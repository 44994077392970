import request from '@/utils/request'
// 提交idfa 和通讯录
function addressBook () {
  const contantList = localStorage.getItem('contact')
  const idfa = localStorage.getItem('idfa') || ''
  if (contantList || idfa) {
    const contant = contantList || JSON.stringify([])
    request.post('addressBook', { contantList: contant, idfa: idfa.replace(/"/g, '') })
  }
}

export default addressBook
