<template>
  <div class="header" :style="{ color, backgroundColor: bgColor }">
    <div v-if="showBackButton" @click="goBack" class="back-btn">
      <!-- <img src="@/assets/img/header-back-btn.png" /> -->
      <van-icon name="arrow-left" :color="color" size="22" />
    </div>
    <div v-if="showLogOut" @click="logOut" class="back-btn">Keluar</div>

    <div class="header_title">{{ title }}</div>
    <div
      v-if="showCustomerService"
      class="right-block kefu"
      @click="showCustomerServiceModal"
    >
      Customer Service
    </div>

    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
    >
      <div class="customer-service-body">
        <p class="title">Customer Service</p>
        <span class="text">uangmucs@outlook.com</span>
        <button type="button" class="confirm-btn" @click="onCloseDialog()">
          Dapat
        </button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Icon } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import store from '@/store'
import router from '@/router'

Vue.use(Icon)
export default {
  name: 'BaseHeaderBar',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  props: {
    title: {
      type: String,
      default: 'UangMu'
    },
    showCustomerService: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    showLogOut: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#fff'
    },
    bgColor: {
      type: String,
      default: '#155c2d'
    }
  },
  data () {
    return {
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    showCustomerServiceModal () {
      this.visible = true
    },
    // 退出登录
    logOut () {
      store.commit('recordInfo', {
        type: 'userInfo',
        value: {}
      })
      localStorage.removeItem('userInfo')
      router.push({
        path: '/login'
      })
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: relative;
  width: 100%;
  height: 88px;
  text-align: center;
  z-index: 3;
  font-weight: 400;
  font-size: 30px;
  .header_title {
    line-height: 88px;
  }
}

.back-btn {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 30px;
  cursor: pointer;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  img {
    width: 21px;
    height: 34px;
  }
}

.right-block {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-weight: 500;
  font-size: 30px;
  color: #ffffff;
}
.kefu {
  margin-right: 20px;
}
.customer-service-title {
  position: relative;

  p {
    .dialog-title;
  }

  .close-icon {
    position: absolute;
    top: 50%;
    right: 32px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.customer-service-body {
  padding: 32px 25px 54px;
  height: 600px;
  background: url("../../../assets/img/kefu_bg.png");
  background-size: 100% 100%;
  p {
    margin: 0;
  }
  .title {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    margin-top: 20px;
  }
  .Email,
  .text {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 34px;
    color: #ffffff;
    margin-top: 280px;
    display: inline-block;
  }
  .confirm-btn {
    .submit-btn;
    width: 500px;
    margin-top: 70px;
  }
}
</style>
