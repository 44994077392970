const RepaymenDetail = () => import('@/views/RepaymenDetail')

export default [
  {
    path: '/repaymenDetail',
    component: RepaymenDetail,
    meta: { requireAuth: true }
  }

]
