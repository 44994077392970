import axios from 'axios'
import Vue from 'vue'
import qs from 'qs'
import { Toast } from 'vant'
import { baseURL } from '@/config'
import store from '@/store'
import router from '@/router'
import { isIOS } from './detect'

Vue.use(Toast)

const showErrorMsg = (msg = 'Internet Error') => {
  Toast(msg)
}

const errorStatus = {
  404: 'Antarmuka Pemrograman Aplikasi tidak tersedia', // 也可能是网络环境问题
  500: 'Sistem sedang sibuk. Silakan coba lagi nanti'
}

const errorCode = {
  503: {
    text: 'Masa berlaku token habis. Silahkan login kembali',
    callback () {
      store.commit('recordInfo', {
        type: 'userInfo',
        value: {}
      })
      localStorage.removeItem('userInfo')
      router.push({
        path: '/login'
      })
    }
  }
}

const instance = axios.create({
  baseURL,
  data: {}
  // timeout: 5000
})

// http请求拦截器
instance.interceptors.request.use(
  config => {
    store.commit('recordInfo', {
      type: 'showLoading',
      value: true
    })
    if (!config.data) {
      config.data = {}
    }
    let userInfo = {}
    const { usdrerID, usdrerToken } = store.state.userInfo
    if (usdrerID) {
      userInfo = {
        usdrerID,
        usdrerToken
      }
    }
    // userInfo = {
    //   usdrerID: '119798',
    //   usdrerToken: 'dd4fe8538da05fde7d5bf7ff21b5561f'
    // }
    const appType = isIOS ? 2 : 1
    const data = {
      ...config.data,
      apdrpType: appType,
      apdrpVersion: '1.0.0',
      dedrviceNo: 'loan',
      ...userInfo
    }
    // 转为formdata数据格式
    config.data = qs.stringify(data)
    return config
  },
  err => {
    Toast(err.request.response.data.msg)
    console.log('err', err)
    return Promise.reject(err)
  }
)
// http响应拦截器
instance.interceptors.response.use(
  res => {
    store.commit('recordInfo', {
      type: 'showLoading',
      value: false
    })
    if (!res || !res.data) return
    if (res.data.code !== 200) {
      if (res.data.code === 504) {
        return Promise.reject(res.data)
      }
      if (res.data.code === 502) {
        Toast(res.data.msg)
        return Promise.reject(res.data)
      }
      // 缺少参数
      if (res.data.code === '501') {
        Toast('Kelainan sistem, silakan login kembali.')
        errorCode[503].callback()
        return Promise.reject(res.data)
      }
      let error = {}
      const o = errorCode[res.data.code]
      if (o) {
        error.msg = 'Masa masuk sudah habis'
        o.callback && o.callback.call(null, res.data)
      } else if (res.data.msg) {
        error = res.data
      } else {
        error.msg = 'Sistem sedang sibuk. Silakan coba lagi nanti'
      }
      showErrorMsg(error.msg)
      return Promise.reject(error)
    }
    if (res.data.data === null) {
      const error = { msg: 'Tidak ada data' }
      return Promise.reject(error)
    }
    return Promise.resolve(res.data.data)
  },
  err => {
    store.commit('recordInfo', {
      type: 'showLoading',
      value: false
    })
    console.log('err', err)
    const error = {}
    if (!err.response) {
      error.msg = 'Internet Error'
    } else {
      const status = err.response.status
      if (errorStatus[status]) {
        error.msg = errorStatus[status]
      } else {
        error.msg = 'Internet Error'
      }
    }
    showErrorMsg(error.msg)
    return Promise.reject(error)
  }
)

export default instance
